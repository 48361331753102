/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize } from 'cerialize';

export class StatletPoint {
  @autoserialize
  id: string;

  @autoserialize
  label: string;

  @autoserialize
  alternativeLabels: {
    [key: string]: string
  };

  @autoserialize
  values: {
    [key: string]: number
  };

  @autoserialize
  type: string;
}
