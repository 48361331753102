/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Injector, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { rendersSingleStatletForType } from '../single-statlet.decorator';
import { StatletGraphTypes } from '../statlet-graph-types.model';
import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../data-type-services/statlet-data-type.decorator';
import { HostWindowService } from '../../../../../../../app/shared/host-window.service';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { GenericConstructor } from '../../../../../../../app/core/shared/generic-constructor';
import { Observable, BehaviorSubject, combineLatest as observableCombineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNotEmpty, hasValue } from '../../../../../../../app/shared/empty.util';

@Component({
  selector: 'ds-atmire-cua-single-statlet-table-bar-combo',
  templateUrl: './single-statlet-table-bar-combo.component.html',
  styleUrls: ['./single-statlet-table-bar-combo.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.TABLE_BAR_COMBO)
@rendersSingleStatletForType(StatletGraphTypes.TABLE_BAR_COMBO, Context.StatletCard)
/**
 * Component rendering a single statlet of type "table"
 */
export class SingleStatletTableBarComboComponent extends DynamicSingleStatletComponent implements AfterViewChecked {
  legend$: Observable<any>;
  loadChart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('table') table: ElementRef;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              protected cdr: ChangeDetectorRef,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  loadColors() {
    super.loadColors();
    this.legend$ = observableCombineLatest(this.data$, this.colors$).pipe(
      map(([data, colors]) => {
        if (isNotEmpty(colors) && isNotEmpty(colors.domain) && isNotEmpty(data) && isNotEmpty(data[0].series)) {
          return colors.domain.map((color, i) => Object.assign({ name: data[0].series[i].name, color }));
        } else {
          return [];
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    // Make sure the height of the table is bigger than its header, meaning the data has loaded
    if (!this.loadChart$.value && hasValue(this.table) && this.table.nativeElement.scrollHeight > 60) {
      // Tell the chart it can start loading
      this.loadChart$.next(true);
      this.cdr.detectChanges();
    }
  }
}

