/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DSpaceObject } from '../../../../../../app/core/shared/dspace-object.model';
import { Context } from '../../../../../../app/core/shared/context.model';
import { PaginatedList } from '../../../../../../app/core/data/paginated-list.model';
import { Statlet } from '../../data/models/statlet.model';
import { Observable, Subscription } from 'rxjs';
import { RemoteData } from '../../../../../../app/core/data/remote-data';
import { AlertType } from '../../../../../../app/shared/alert/aletr-type';
import { StatletDataService } from '../../data/services/statlet-data.service';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../app/core/shared/operators';
import { ensureArrayHasValue, hasValue } from '../../../../../../app/shared/empty.util';
import { map } from 'rxjs/operators';
import { StatletPosition } from '../../data/models/statlet-position.model';
import { hasStatletData } from '../../data/models/statlet.utils';
import { getAcceptedGraphWidths } from '../../single-statlet/graph-types/statlet-graph-types.model';

@Component({
  selector: 'ds-atmire-cua-inline-statlets-section',
  templateUrl: './inline-statlets-section.component.html',
  styleUrls: ['./inline-statlets-section.component.scss']
})
/**
 * Component displaying all Statlets for a given DSpaceObject
 * Can only show inline Statlets, shown by default, can't be hidden
 * It is required the Statlets have a {@link StatletPosition} set to "inline"
 */
export class InlineStatletsSectionComponent implements OnInit, OnDestroy {
  /**
   * The object to display statlets for
   */
  @Input() dso: DSpaceObject;

  readonly context = Context.StatletInline;

  /**
   * The statlets to display for the object
   */
  statletsRD$: Observable<RemoteData<PaginatedList<Statlet>>>;

  /**
   * Does any of the statlets have any data?
   */
  hasData$: Observable<boolean>;

  /**
   * The list of Subscription
   * @type {Array}
   */
  protected subs: Subscription[] = [];

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  public AlertTypeEnum = AlertType;

  constructor(protected statletService: StatletDataService) { }

  ngOnInit(): void {
    this.statletsRD$ = this.statletService.searchByObject(this.dso._links.self.href, StatletPosition.Inline);
    this.hasData$ = this.statletsRD$.pipe(
      getFirstSucceededRemoteDataPayload(),
      map((statlets) => statlets.page),
      ensureArrayHasValue(),
      map((statlets) => statlets.some(hasStatletData))
    );
    this.subs.push(
      this.statletsRD$.pipe(getFirstSucceededRemoteDataPayload()).subscribe((statlets) => {
        statlets.page.forEach((statlet) => {
          if (statlet.style.width === null) {
            console.warn(`An incorrect width was defined for statlet \"${statlet.shortName}\". Accepted widths: ${getAcceptedGraphWidths()}`);
          }
        });
      })
    );
  }

  /**
   * Unsubscribe from all open subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
