<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="row mx-0" #container>
  <div class="col-12 col-sm-6 table-wrapper px-0" #table>
    <ds-atmire-cua-single-statlet-table [statlet]="statlet" [striped]="true"></ds-atmire-cua-single-statlet-table>
  </div>
  <div class="col-6 d-none d-sm-block bar-wrapper px-0" #wrapper *ngVar="(data$ | async) as data">
    <ngx-charts-bar-horizontal-2d *ngIf="(loadChart$ | async)"
                                  [results]="data"
                                  [view]="[container.offsetWidth / 2, table.offsetHeight - 50]"
                                  [scheme]="colors$ | async"
                                  [xAxis]="true"
                                  [barPadding]="0"
                                  [groupPadding]="30"
    ></ngx-charts-bar-horizontal-2d>
  </div>
</div>
<div class="row mx-0 d-none d-sm-block">
  <div class="col-12 px-0 py-2">
    <span class="legend-label" *ngFor="let l of (legend$ | async)">
      <span class="legend-label-color" [style]="'background-color: ' + l.color"></span>
      <span>{{ l.name }}</span>
    </span>
  </div>
</div>

