/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dsTranslateWithDefault' })
export class TranslateWithDefaultPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: string, args?: any): string {
    const translation = this.translate.instant(key, args);
    return translation !== key ? translation : args?.defaultValue || key;
  }
}
