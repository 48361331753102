<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(statletsRD$ | async) as statletRD">
  <ng-container *ngFor="let metadataStatlet of metadataStatlets">
    <div *ngIf="metadataStatlet.statlet.graphtype" class="mb-2">
      <ds-atmire-cua-single-statlet-wrapper-loader #singleMetadataStatlet [statlet]="metadataStatlet.statlet" [context]="context"></ds-atmire-cua-single-statlet-wrapper-loader>
      <div class="metadata-help mb-3" *ngIf="metadataStatlet.help">
        {{ metadataStatlet.help }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngVar="statletRD?.payload?.page as statlets">
    <ng-container *ngFor="let statlet of statlets">
      <div *ngIf="statlet.graphtype" class="mb-2">
        <ds-atmire-cua-single-statlet-wrapper-loader #singleStatlet [statlet]="statlet" [context]="context"></ds-atmire-cua-single-statlet-wrapper-loader>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="statletRD?.isLoading">
    <ds-loading [showMessage]="false"></ds-loading>
  </div>
  <ds-alert *ngIf="statletRD?.hasFailed" [content]="'statlets.error' | translate" [type]="AlertTypeEnum.Error"></ds-alert>
</ng-container>
