/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { isNotEmpty } from '../../../../../app/shared/empty.util';
import { TranslateService } from '@ngx-translate/core';
import { MetadataStatlet } from './metadata-statlet.model';
import { InlineStatletsSectionComponent } from '../statlets-section/inline/inline-statlets-section.component';
import { StatletDataService } from '../data/services/statlet-data.service';
import { isNumeric } from 'rxjs/internal-compatibility';

@Component({
  selector: 'ds-atmire-cua-inline-metadata-statlets-section',
  templateUrl: './inline-metadata-statlets-section.component.html',
  styleUrls: ['./inline-metadata-statlets-section.component.scss'],
})
/**
 * Component displaying all Statlets for a given DSpaceObject
 * Can only show inline Statlets, shown by default, can't be hidden
 * Includes metadata statlets
 */
export class InlineMetadataStatletsSectionComponent extends InlineStatletsSectionComponent implements OnInit {

  metadataStatlets: MetadataStatlet[];

  constructor(protected statletService: StatletDataService,
              protected translate: TranslateService) {
    super(statletService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.metadataStatlets = [];
    this.addMetadataStatlet('okr.statistics.combined', 'InlineViewsCombined');
  }

  private addMetadataStatlet(metadataField: string, shortName: string) {
    const metadataValue = this.dso.firstMetadataValue(metadataField);
    if (isNotEmpty(metadataValue) && isNumeric(+metadataValue)) {
      const i18nHelp = `statlets.okr.inline.metadata.help.${shortName}`;
      const helpMsg = this.translate.instant(i18nHelp);
      this.metadataStatlets.push(
        new MetadataStatlet(metadataValue, this.dso.id, shortName,
          this.translate.instant(`statlets.okr.inline.metadata.label.${shortName}`),
          helpMsg !== i18nHelp ? helpMsg : null,
        ),
      );
    }
  }
}
