/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Injector, Input } from '@angular/core';
import { rendersSingleStatletForType } from '../single-statlet.decorator';
import { StatletGraphTypes } from '../statlet-graph-types.model';
import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { map } from 'rxjs/operators';
import { hasNoValue, hasValue } from '../../../../../../../app/shared/empty.util';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../app/shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../app/core/shared/generic-constructor';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-table',
  templateUrl: './single-statlet-table.component.html',
  styleUrls: ['./single-statlet-table.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.TABLE)
@rendersSingleStatletForType(StatletGraphTypes.LIST)
@rendersSingleStatletForType(StatletGraphTypes.TABLE, Context.StatletCard)
@rendersSingleStatletForType(StatletGraphTypes.LIST, Context.StatletCard)
/**
 * Component rendering a single statlet of type "table"
 */
export class SingleStatletTableComponent extends DynamicSingleStatletComponent {
  @Input() displayColours = false;
  @Input() inverseData: boolean = null;

  /**
   * Whether or not the table should have a striped display (alternating between white and grey)
   */
  @Input() striped = true;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  /**
   * Should the data be reversed?
   * If inverseData is provided, prioritize this property
   * If not, check the Statlet's graphtype property
   */
  isInverseData(): boolean {
    return (hasValue(this.inverseData) && this.inverseData) || (hasNoValue(this.inverseData) && super.isInverseData());
  }

  loadColors() {
    this.colors$ = this.data$.pipe(
      map((data) => Object.assign({ domain: this.colorService.getColorSet(data.length, this.statlet.style.palette ? this.statlet.style.palette : this.palette) }))
    );
  }
}
