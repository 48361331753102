/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Statlet } from '../data/models/statlet.model';
import {
  StatletGraphTypes
} from '../single-statlet/graph-types/statlet-graph-types.model';
import { StatletPoint } from '../data/models/statlet-point.model';
import { StatletHeader } from '../data/models/statlet-header.model';


export class MetadataStatlet {
  statlet: Statlet;
  help?: string;

  constructor(metadataValue: string, dsoId: string, shortName: string, label: string, help?: string) {
    this.statlet = Object.assign(new Statlet(), {
      id: `${dsoId}_${shortName}`,
      uuid: `${dsoId}_${shortName}`,
      dso: dsoId,
      graphtype: StatletGraphTypes.NUMBER,
      points: [
        Object.assign(new StatletPoint(), {
          id: shortName,
          label,
          values: {
            any: +metadataValue,
          },
        }),
      ],
      columnHeaders: [
        Object.assign(new StatletHeader(), {
          headerCategory: 'dso',
          headerId: 'any',
          headerName: 'any',
        }),
      ],
      rowHeaders: [
        Object.assign(new StatletHeader(), {
          headerCategory: 'dso',
          headerId: shortName,
          headerName: label,
        }),
      ],
      style: {},
      shortName,
    });
    this.help = help;
  }
}
